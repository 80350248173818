import React from "react"
import { Link } from "gatsby"

import { FaEnvelope } from "react-icons/fa"
import { FaMobileAlt } from "react-icons/fa"
import { FaMapMarkerAlt } from "react-icons/fa"

const HeaderContact = ({ companyContactNumber }) => {
  const color1 = "to right bottom, rgba(0, 0, 0, 0.9)"
  const color2 = "rgba(0, 0, 0, 0.8)"
  const str = "linear-gradient(" + color1 + "," + color2 + "),"

  const customStyle = {
    backgroundImage: str + "url(" + require("../images/blog-2.jpeg") + ")",
    backgroundRepeat: "no-repeat",
  }
  return (
    <>
      <section className="header-contact container " style={customStyle}>
        <div className="header-secondary__wrapper  ">
          <div className="nav__right--icon">
            <i className="fas fa-align-right"></i>
          </div>

          <div className="hero-contact">
            <div className="hero-contact__group">
              <h1 className="hero-contact__heading">
                Would you prefer to talk to someone{" "}
                <span className="hero-contact__heading--highlight">
                  in person?
                </span>
              </h1>
            </div>
            <div className="hero-contact__group mt-4">
              <div className="hero-contact__left">
                <h2 className="hero-contact__speak">Speak to our Expert:</h2>
                <div className="mt-5">
                  <a
                    href={`tel:+${companyContactNumber}`}
                    className="hero-contact__phone"
                  >
                    <FaMobileAlt className="hero-contact__phone--icon" />
                    {companyContactNumber}
                  </a>
                </div>
              </div>
              <div className="hero-contact__right">
                <p className="hero-contact__address">
                  <FaMapMarkerAlt className="hero-contact__address--icon" />
                  021 Apple St. General Santos City, Philippines 9500
                </p>
                <p className="hero-contact__email">
                  <FaEnvelope className="hero-contact__email--icon" />
                  greatwebph@gmail.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HeaderContact
