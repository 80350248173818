import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FooterGlobal from "../components//FooterGlobal"
import HeaderContact from "../components/HeaderContact"
import MainNav from "../components/MainNav"

import { FaSuitcase } from "react-icons/fa"
import { FaBuilding } from "react-icons/fa"
import { FaExclamationTriangle } from "react-icons/fa"

export const getData = graphql`
  {
    wpgraphql_greatweb {
      pageBy(uri: "contact") {
        pageSiteMetaTitle_acf {
          sitemetatitle
        }
        pageSiteMetaDescription_acf {
          siteMetaDescription
        }
      }
      homePageBy: pageBy(uri: "home") {
        home_pagesection_acf {
          sections {
            generalSettings {
              announcement
              countdownData
              companyAddress
              companyContactNumber
              companyOverview
              facebookLink
              linkedinLink
              supportEmail
              twiiterLink
            }
          }
        }
      }
    }
  }
`

const Contact = ({ data }) => {
  const [fields, setFields] = useState({
    company: "",
    primaryEmail: "",
    companySize: "1-4 Employees",
    contactNum: "",
    projectTitle: "",
    projectBudget: "50,000-₱100,000",
    message: "",
    error: false,
    loading: false,
    success: false,
  })

  const [showNext, setShowNext] = useState(0)

  const {
    company,
    primaryEmail,
    companySize,
    contactNum,
    projectTitle,
    projectBudget,
    message,
    error,
    loading,
    success,
  } = fields

  const handleChange = name => e => {
    setFields({
      ...fields,
      [name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (showNext === 0) {
      if (
        company !== "" &&
        primaryEmail !== "" &&
        companySize !== "" &&
        contactNum !== ""
      ) {
        setShowNext(1)
      }
    }

    if (showNext === 1) {
      if (projectTitle !== "" && projectBudget !== "" && message !== "") {
        setFields({
          ...fields,
          loading: true,
          error: false,
          success: false,
        })

        console.log(`submitting...`)
        const formspreeAction = "https://formspree.io/f/xdopkpkz"
        const form = e.target
        const data = new FormData(form)
        const xhr = new XMLHttpRequest()
        xhr.open(form.method, formspreeAction)
        xhr.setRequestHeader("Accept", "application/json")
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== XMLHttpRequest.DONE) return
          if (xhr.status === 200) {
            setFields({
              ...fields,
              loading: false,
              error: false,
              success: true,
            })
            form.reset()
            setShowNext(2)
            console.log(`Message Sent Successfully`)
          } else {
            setFields({
              ...fields,
              loading: false,
              error: true,
              success: false,
            })
            console.log(`Message Failed to Send`)
          }
        }
        xhr.send(data)
      } else {
        console.log(`disable Submit`)
      }
    }
  }

  const {
    wpgraphql_greatweb: {
      pageBy: {
        pageSiteMetaTitle_acf: { sitemetatitle },
        pageSiteMetaDescription_acf: { siteMetaDescription },
      },
      homePageBy: {
        home_pagesection_acf: {
          sections: {
            generalSettings: {
              announcement,
              countdownData,
              companyAddress,
              companyContactNumber,
              companyOverview,
              facebookLink,
              linkedinLink,
              supportEmail,
              twiiterLink,
            },
          },
        },
      },
    },
  } = data

  return (
    <Layout
      announcement={!announcement ? "" : announcement}
      countdownData={!countdownData ? "" : countdownData}
    >
      <SEO title={sitemetatitle} description={siteMetaDescription} />
      <MainNav active="contact" />
      <HeaderContact companyContactNumber={companyContactNumber} />

      <div className="contact container">
        {showNext !== 2 && (
          <h2 className="contact__heading">Let's do great work together.</h2>
        )}

        <div className="contact__wrapper">
          <div className="contact__question">
            {showNext === 0 && (
              <div className="contact__question--iconWrapper">
                <FaSuitcase className="contact__question--icon" />
              </div>
            )}

            {showNext === 1 && (
              <div className="contact__question--iconWrapper">
                <FaBuilding className="contact__question--icon" />
              </div>
            )}

            {showNext === 0 && (
              <p className="contact__question--label">
                1. Tell us about your company
              </p>
            )}

            {showNext === 1 && (
              <p className="contact__question--label">
                2. Tell us about your project
              </p>
            )}
          </div>
          <form className="contact__form" onSubmit={handleSubmit} method="POST">
            <input
              type="hidden"
              name="_subject"
              value={`New Contact - ${company} - Great Web `}
            />
            <div
              className={` ${
                showNext !== 0 ? "hide-item" : "contact__form--row"
              }`}
            >
              <div className="contact__form-group">
                <label htmlFor="companyname" className="contact__form--label">
                  Company Name
                </label>
                <input
                  type="text"
                  className="input"
                  name="company"
                  value={company}
                  onChange={handleChange("company")}
                />
              </div>
              <div className="contact__form-group">
                <label htmlFor="companysize" className="contact__form--label">
                  Company Size
                </label>
                <select
                  className="select"
                  name="companySize"
                  value={companySize}
                  onChange={handleChange("companySize")}
                >
                  <option value="1-4 Employees">1-4 Employees</option>
                  <option value="5-10 Employees">5-10 Employees</option>
                  <option value="11-50 Employees">11-50 Employees</option>
                  <option value="51-200 Employees">51-200 Employees</option>
                  <option value="201-500 Employees">201-500 Employees</option>
                  <option value="500+ Employees">500+ Employees</option>
                </select>
              </div>
            </div>
            <div
              className={` ${
                showNext !== 0 ? "hide-item" : "contact__form--row"
              }`}
            >
              <div className="contact__form-group">
                <label htmlFor="companyemail" className="contact__form--label">
                  Primary Email
                </label>
                <input
                  type="text"
                  name="primaryEmail"
                  className="input"
                  value={primaryEmail}
                  onChange={handleChange("primaryEmail")}
                />
              </div>
              <div className="contact__form-group">
                <label
                  htmlFor="companycontact"
                  className="contact__form--label"
                >
                  Contact Number
                </label>
                <input
                  type="number"
                  name="contactNum"
                  className="input"
                  value={contactNum}
                  onChange={handleChange("contactNum")}
                />
              </div>
            </div>

            {showNext === 1 && (
              <>
                <div className="contact__form--row">
                  <div className="contact__form-group">
                    <label
                      htmlFor="projecttitle"
                      className="contact__form--label"
                    >
                      Project Title
                    </label>
                    <input
                      type="text"
                      name="project-title"
                      className="input"
                      value={projectTitle}
                      onChange={handleChange("projectTitle")}
                    />
                  </div>
                  <div className="contact__form-group">
                    <label
                      htmlFor="projectbudget"
                      className="contact__form--label"
                    >
                      Project Budget
                    </label>
                    <select
                      className="select"
                      name="project-budget"
                      value={projectBudget}
                      onChange={handleChange("projectBudget")}
                    >
                      <option value="50,000-₱100,000">₱50,000-₱100,000</option>
                      <option value="100,000-₱150,000">
                        ₱100,000-₱150,000
                      </option>
                      <option value="150,000-₱200,000">
                        ₱150,000-₱200,000
                      </option>
                      <option value="200,000++">₱200,000++</option>
                    </select>
                  </div>
                </div>
                <div className="contact__form-group">
                  <textarea
                    name="message"
                    value={message}
                    onChange={handleChange("message")}
                    cols="30"
                    rows="5"
                    className="textarea"
                    placeholder="Tell us more about your project"
                  ></textarea>
                </div>
              </>
            )}

            <div className="contact__step">
              {showNext === 0 && (
                <p className="contact__step--progress">STEP 1/2</p>
              )}
              {showNext === 1 && (
                <p className="contact__step--progress">STEP 2/2</p>
              )}

              {showNext === 0 && (
                <button
                  className={`contact__step--btn btn-primary ${
                    company && primaryEmail && companySize && contactNum
                      ? "btn"
                      : "btn-disable"
                  }`}
                >
                  Next
                </button>
              )}

              {showNext === 1 && (
                <button
                  disabled={loading}
                  className={`contact__step--btn btn-primary ${
                    projectTitle && projectBudget && message
                      ? "btn"
                      : "btn-disable"
                  }`}
                >
                  {loading ? "Sending..." : "Submit"}
                </button>
              )}
            </div>

            {error && (
              <div className="contact__form--error dflex justify-content-between align-items-center">
                <h3>Message Failed. Please try submitting again</h3>
                <div>
                  <FaExclamationTriangle />
                </div>
              </div>
            )}
          </form>

          {success && (
            <div className="contact__form--success">
              <div className="contact__form--success--imgWrapper">
                <img
                  className="contact__form--success--img"
                  src={require("../images/survey-mail.png")}
                  alt="Thank you"
                />
              </div>
              <h3 className="contact__form--success--heading">Thank You!</h3>
              <p className="contact__form--success--subheading">
                Your message has been successfully sent. We will contact you
                very soon!
              </p>
            </div>
          )}
        </div>
      </div>
      <FooterGlobal
        companyAddress={companyAddress}
        companyContactNumber={companyContactNumber}
        companyOverview={companyOverview}
        facebookLink={facebookLink}
        linkedinLink={linkedinLink}
        supportEmail={supportEmail}
        twiiterLink={twiiterLink}
      />
    </Layout>
  )
}

export default Contact
